import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import MuiTabs from '@material-ui/core/Tabs'
import TabHead from '@material-ui/core/Tab'
import TabPanel from './tabpanel'
import { useTheme } from '@hooks'

const useStyles = makeStyles((theme) => ({
  /* tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  }, */
  tabHeads: {
    margin: theme.spacing(6, 0, 10, 0),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
      '& > div': { width: '100%', display: 'flex', justifyContent: 'center' },
    },
    [theme.breakpoints.down('md')]: {
      '& > div': { display: 'flex', justifyContent: 'center' },
    },
  },
  radio: {
    '& .MuiTab-root': {
      fontFamily: 'inherit',
      fontSize: '16px',
      minWidth: '0px',
      minHeight: '0',
      padding: 0,
      '&:not(:last-child)': {
        marginRight: theme.spacing(10),
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: theme.spacing(10),
      },
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: theme.spacing(4),
        borderRadius: '50%',
        background:
          'radial-gradient(circle, #fff, #fff 7px, #fff 10px, #000 10px, #000 12px)',
      },
    },
    '& .Mui-selected .MuiTab-wrapper::before': {
      background:
        'radial-gradient(circle, #000, #000 6px, #fff 7px, #fff 10px, #000 10px, #000 12px)',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
  simple: {
    '& .MuiTab-root': {
      ...theme.typography.glossaryTab,
      fontFamily: 'inherit',
      flexGrow: '0',
      minWidth: '0px',

      '&:not(:last-child)': {
        //marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('md')]: {
        //minWidth: theme.spacing(9),
      },
    },
    '& .Mui-selected': {
      //color: theme.palette.red.main,
      color: '#E3000F',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '60%',
      flexWrap: 'wrap',
      margin: '1px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '80%',
        justifyContent: 'flex-start',
      },
    },
  },
}))

function GlossaryTabs({ className, children, tabHeads, type, active }) {
  const classes = useStyles()
  const [value, setValue] = useState(
    active || tabHeads.findIndex((h) => !h.disabled)
  )

  const { screens } = useTheme()
  const [isMobile, setIsMobile] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setValue(active)
  }, [active])

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  useEffect(() => {
    setIsMobile(window.innerWidth < parseInt(screens.md, 9))
  }, [screens])

  function renderPanels() {
    return children.map((child, i) => {
      return (
        <TabPanel key={`${child.created}${i}`} index={i} value={value}>
          {child}
        </TabPanel>
      )
    })
  }

  function renderHeads() {
    return tabHeads.map((head, i) => {
      return (
        <TabHead
          key={`${head.created}${i}`}
          label={head.label}
          onClick={head.onClick}
          disabled={head.disabled}
          className="text-base flex justify-center"
          {...a11yProps(i)}
        />
      )
    })
  }

  return (
    <div
      //className={classes.tabsRoot}
      className="grow bg-white"
    >
      <MuiTabs
        className={clsx(classes.tabHeads, classes[type])}
        scrollButtons="off"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="tabs"
      >
        {renderHeads()}
      </MuiTabs>
      {renderPanels()}
    </div>
  )
}

GlossaryTabs.propTypes = {
  children: PropTypes.any,
  tabHeads: PropTypes.array,
  className: PropTypes.string,
  type: PropTypes.oneOf(['simple', 'radio']),
  active: PropTypes.number,
}

GlossaryTabs.defaultProps = {
  type: 'radio',
  active: 0,
}

export default GlossaryTabs
